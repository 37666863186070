define('assets/js/modules/screen', ['jquery'], function($) {
    return {
        sizes: {
            xs: {
                min: 0
            },
            sm: {
                min: 768
            },
            md: {
                min: 1024
            },
            lg: {
                min: 1381
            }
        },

        isXs: function() {
            return this.getScreenName() === 'xs';
        },
        isSm: function() {
            return this.getScreenName() === 'sm';
        },
        isMd: function() {
            return this.getScreenName() === 'md';
        },
        isLg: function() {
            return this.getScreenName() === 'lg';
        },
        isSmall: function() {
            return this.isXs() || this.isSm();
        },
        isBig: function() {
            return this.isMd() || this.isLg();
        },
        getWidth: function() {
            return window.innerWidth;
        },
        getHeight: function() {
            return $(window).height();
        },
        getScreenName: function() {
            var _this = this;
            var screenName = '';
            var screenWidth = this.getWidth();

            Object.keys(this.sizes).forEach(function(key) {
                if (screenWidth >= _this.sizes[key].min) {
                    screenName = key;
                }
            });

            return screenName;
        },
        /**
         * @returns {boolean}
         */
        isVerticalOrientation: function() {
            if (typeof screen.orientation === 'object') {
                return screen.orientation.angle === 0;
                //iOS devices
            } else if (window.orientation !== undefined) {
                return window.orientation === 0 || window.orientation === 180;
            } else {
                return screen.height > screen.width;
            }
        },

        isElementVisible: function($element) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $element.offset().top;
            var elemBottom = elemTop + $element.height();

            return (elemTop <= docViewBottom && elemTop >= docViewTop) || (elemBottom <= docViewBottom && elemBottom >= docViewTop);
        }
    };
});
