define(['jquery', 'assets/js/common/adobe/DataLayer', 'opensoft-jquery.royalslider/jquery.royalslider.min'], function($, dataLayer) {
    var defaultParams = {
        keyboardNavEnabled: true,
        addActiveClass: true,
        loop: true,
        transitionType: 'fade',
        arrowsNavAutoHide: false,
        imageScalePadding: 0,
        imageAlignCenter: false,
        imageScaleMode: 'none',
        autoPlay: {
            enabled: true,
            delay: 5000
        }
    };

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    return function($slider, params) {
        params = $.extend(defaultParams, params);

        var $preloadImage = $('.royalSlider-preload-image');
        if ($preloadImage.length) {
            //suppose that $preloadImage is loaded and .royalSlider-container is already stretched properly
            $('.royalSlider-container').css('min-height', 0);
        }

        if ($slider.children().length) {
            $slider.royalSlider(params);
            if ($slider.data('royalSlider').slides.length === 1) {
                $slider.find('.rsNav').hide();
            }
            $slider.data('royalSlider').slides[0].holder.on('rsAfterContentSet', function() {
                $preloadImage.remove();
            });
            $slider.data('royalSlider').ev.on('rsAfterContentSet', function(e, slideObject) {
                $(slideObject.holder)
                    .find('.hidden')
                    .removeClass('hidden');
            });

            var slides = [];
            $slider.data('royalSlider').ev.on('rsAfterSlideChange', function(e) {
                if (!$slider.isInViewport()) {
                    return;
                }
                var currentSlide = $slider.data('royalSlider').currSlideId;
                if (!slides.includes(currentSlide)) {
                    slides.push(currentSlide);
                    dataLayer.pushData(dataLayer.ROYAL_SLIDER_CHANGE, e);
                }
            });
        }
    };
});
