define(['jquery', 'assets/js/common/router/index'], function($, router) {
    return {
        /**
         * @returns {Deferred}
         */
        loadSdk: function() {
            var facebookLoadedPromise = $.Deferred();

            // check if facebook already initialized
            if (typeof FB !== 'undefined') {
                facebookLoadedPromise.resolve();
            }

            /**
             * When facebook sdk is loaded it fires `window.fbAsyncInit()`.
             * Handle this
             */
            window.fbAsyncInit = function() {
                FB.init({
                    appId: ONP.config.facebookClientId,
                    status: true,
                    xfbml: true,
                    version: ONP.config.facebookGraphApiVersion
                });

                facebookLoadedPromise.resolve();
            };

            // facebook code
            (function(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/all.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');

            return facebookLoadedPromise;
        },

        /**
         * @param {bool} redirect
         */
        login: function(redirect) {
            FB.getLoginStatus(function(response) {
                var data = { service: 'facebook' };
                if (redirect === true) {
                    data._destination = window.location.href;
                }
                if (response.status === 'connected') {
                    document.location = router.generate('/connect/{service}', data);
                } else {
                    FB.login(
                        function(response) {
                            if (response.authResponse) {
                                document.location = router.generate('/connect/{service}', data);
                            }
                        },
                        { scope: 'email' }
                    );
                }
            });
        }
    };
});
