function initMarketingLoginPopup(message, className) {
    message = message || '';
    className = className || 'marketing-login-popup';

    //rewrite onclick handlers for elements with 'onclick' attribute
    jQuery('.' + className + '[onclick]').each(function() {
        jQuery(this).data('onclick', this.onclick);
        this.onclick = function() {
            return false;
        };
    });

    jQuery('.' + className).on('click', function(e) {
        var $target = jQuery(this);
        if ($target.hasClass(className) && !ONP.user.loggedIn) {
            e.preventDefault();
            e.stopImmediatePropagation();

            require(['assets/js/modules/popup/AuthPopup'], function({ default: AuthPopup }) {
                //init AuthPopup
                var loginPopup = new AuthPopup({
                    templateOptions: {
                        message: message
                    },
                    onComplete: function() {
                        jQuery('.' + className).removeClass(className);
                        triggerClick($target);
                    },
                    onCancel: function() {
                        $target.removeClass(className);
                        triggerClick($target);
                    }
                });
                loginPopup.showLoginFormAction();
            });
        }
    });
}

function triggerClick($target) {
    var href = $target.attr('href');

    if ($target.data('onclick')) {
        $target.data('onclick').call($target.get(0));
    } else if (href && href != '#') {
        window.location = href;
    } else {
        $target.click();
    }
}
