export default class {
    /**
     *
     * @param {PopupLight} popup
     */
    constructor(popup) {
        this._popup = popup;
    }

    /**
     *
     * @param {number} milliseconds
     */
    open(milliseconds) {
        this._popup.open();
        this._counter = setTimeout(this.close.bind(this), milliseconds);
    }
    close() {
        this._popup.close();
        clearTimeout(this._counter);
    }
}
